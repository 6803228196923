import React from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, PerspectiveCamera, Box } from "@react-three/drei";
import { useGLTF } from "@react-three/drei";

import "./App.css";

function Model({ url }) {
  const { scene } = useGLTF(url);

  // Log the entire scene to make sure it's loaded properly
  console.log("Loaded scene:", scene);
  scene.scale.set(7, 7, 7);
  scene.rotateX(0.349066);

  return (
    <>
      <primitive object={scene} />
    </>
  );
}

function App() {
  const modelUrl = "./model/poly.glb"; // Make sure this is the correct path

  return (
    <Canvas>
      <PerspectiveCamera position={[0, 0, 5]} makeDefault />
      <ambientLight intensity={1} />
      <directionalLight position={[0, 1, 2]} />
      <Model url={modelUrl} />
      <OrbitControls />
    </Canvas>
  );
}

export default App;
